import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const ImageTeaserDrKrokowski = ({ props }) => (
	<StaticQuery
		query={graphql`
			query {
				image: file(relativePath: { eq: "team/dr_krokowski.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 300, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={(data) => (
			<Img {...props} fluid={data.image.childImageSharp.fluid} />
		)}
	/>
);
export default ImageTeaserDrKrokowski;
