import React, { useEffect, useState } from 'react';
import Column from '../components/Column';
import Grid from '../components/Grid';
import ImageTeaserFreieStellen from '../components/images/ImageTeaserFreieStellen';
import ImageTeaserHautkrebs from '../components/images/ImageTeaserHautkrebs';
import ImageTeaserBotox from '../components/images/ImageTeaserBotox';
import ImageTeaserPraxis from '../components/images/ImageTeaserPraxis';
import ImageTeaserContact from '../components/images/ImageTeaserContact';
import ImageTeaserCorona from '../components/images/ImageTeaserCorona';
import ImageTeaserDrKrokowski from '../components/images/ImageTeaserDrKrokowski';
import ImageTeaserDrFueller from '../components/images/ImageTeaserDrFueller';
import Layout from '../components/layout';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import Teaser from '../components/teaser';
import { getVacationData } from '../utils/constants';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const IndexPage = ({ location, ...props }) => {
	const [vacationData, setVacationData] = useState(null);

	useEffect(() => {
		// Check vacation
		const vacationData = getVacationData();

		setVacationData(vacationData.vacationData);
	}, []);

	return (
		<Layout
			location={location}
			title="Startseite"
			pageTitle={
				vacationData
					? 'Liebe Patienten/ - innen,'
					: 'Herzlich Willkommen'
			}
			// preContent={
			// 	<>
			// 		<div className="bg-white p-2 shadow-md">
			// 			<div className="bg-orange text-white p-4 text-center">
			// 				<strong>
			// 					Bitte beachten Sie, dass das
			// 					Gesundheitsministerium ab 01.10.2022
			// 					verpflichtend eine FFP2 Maskenpflicht in den
			// 					Arztpraxen angeordnet hat.
			// 				</strong>
			// 			</div>
			// 		</div>
			// 	</>
			// }
			// hidePageTitle
			hideBreadcrumbs
			hideSidebar
			isHomepage
			{...props}
		>
			<Section noTopBorder noTopSpacing>
				<div className="container">
					{/* <SectionTitle>Aktuelles</SectionTitle> */}
					{vacationData ? (
						<div>
							{vacationData.text}
							<p>
								<strong>Dr. med. Michael Krokowski</strong>
								<br />
								<strong>mit dem gesamten Praxisteam.</strong>
							</p>
						</div>
					) : (
						<div>
							<Grid>
								<Column md="2/3">
									<p>
										Unsere Leistungen umfassen das gesamte
										Spektrum der gesetzlichen und privaten
										Krankenversicherung als auch die von den
										Versicherungen nicht gedeckten
										Selbstzahlerleistungen.
									</p>
									<p>
										Die ästhetische Dermatologie rundet
										unser Behandlungsspektrum sinnvoll für
										diejenigen ab, die ihre Haut als etwas
										ganz Besonderes empfinden und mit dieser
										bewusst den interaktiven Kontakt zu
										anderen Menschen begehen.
									</p>
									<p>
										Die Devise für unsere Behandlungen
										lautet: Patientenorientierte
										medizinische Beratung und Behandlung in
										ruhiger und entspannter Atmosphäre auf
										höchst fachlichem Niveau.
									</p>
									<p>
										Begleiten Sie uns auf einem kleinen
										Rundgang und erfahren Sie mehr über uns
										und unsere Praxis.
									</p>
									<p>
										Für spezielle Fragen nutzen Sie unser
										Kontaktformular oder rufen Sie uns an.
										Wir beraten Sie wirklich gerne!
									</p>
									<p>
										<strong>
											Dr. med. Michael Krokowski
										</strong>
									</p>
								</Column>
								<Column md="1/3">
									<Carousel
										autoPlay
										interval={3000}
										showStatus={false}
										showArrows={false}
										showIndicators={false}
										dynamicHeight
										swipeable
										infiniteLoop
										showThumbs={false}
										className="bg-white"
									>
										<div>
											<Teaser
												to="/aktuelles/informationen-zu-corona"
												title="Informationen zur Corona-Krise"
												image={<ImageTeaserCorona />}
											/>
										</div>

										<div className="bg-white p-2 shadow-md">
											<div className="bg-blue text-white p-4 text-center">
												<h3>
													Neu IPL / Neodym-YAG Laser
												</h3>

												<div className="mb-3 text-left">
													<ul>
														<li>
															Couperose,
															Gefäßerweiterungen
														</li>
														<li>Rosacea</li>
														<li>
															Besenreiser Beine
														</li>
														<li>Haarentfernung</li>
													</ul>
												</div>
											</div>
										</div>
									</Carousel>
								</Column>
							</Grid>
						</div>
					)}
				</div>
			</Section>

			<Section bgGreyLighter>
				<div className="container">
					<SectionTitle>Unser Arzt</SectionTitle>
					<Grid noNegativeX className="pt-4 mx-auto justify-center">
						<Column md="1/2" style={{ maxWidth: '300px' }}>
							<Teaser
								to="/praxis/dr-med-michael-h-krokowski"
								title="Dr. med. Michael Krokowski"
								image={<ImageTeaserDrKrokowski />}
							/>
						</Column>
					</Grid>
				</div>
			</Section>

			<Section>
				<div className="container">
					<SectionTitle>Erfahren Sie mehr</SectionTitle>
					<Grid className="pt-4">
						<Column md="1/3">
							<Teaser
								to="/praxis"
								title="Unsere Praxis"
								image={<ImageTeaserPraxis />}
							/>
						</Column>
						<Column md="1/3">
							<Teaser
								to="/medizinische-dermatologie"
								title="Medizinische Dermatologie"
								image={<ImageTeaserHautkrebs />}
							/>
						</Column>
						<Column md="1/3">
							<Teaser
								to="/aesthetische-dermatologie"
								title="Ästhetische Dermatologie"
								image={<ImageTeaserBotox />}
							/>
						</Column>
					</Grid>
				</div>
			</Section>
		</Layout>
	);
};

export default IndexPage;
